import { loggedIn } from 'bv-helpers/session';
import { t } from '../services/i18n';

const SEOTitle = (title, translationKey) => {
  if (loggedIn() || !translationKey) {
    return title;
  }

  return t(`javascript.seo.${translationKey}`, { title });
};

const SEOSportTitle = (title) => SEOTitle(title, 'sport_title');
const SEOEventTitle = (title) => SEOTitle(title, 'event_title');

export {
  SEOSportTitle,
  SEOEventTitle,
};
